import React from 'react';
import { Tooltip, Typography, Zoom } from '@mui/material';
import { CompanyProfileCell, FolioWithProfileAndAlerts, SiiStatusTextCell, TimeInStageCell } from '@fingo/lib/components/cells';
import InvoiceDocumentsCell from '../components/ratification/ratification-documents-review/InvoiceDocumentsCell';
import OCHesAlerts from '../components/ratification/components/OCHesAlerts';

export const COLUMNS = [
  {
    field: 'folio',
    headerName: 'Folio',
    type: 'string',
    align: 'center',
    maxWidth: 70,
    renderCell: ({ row }) => (
      <FolioWithProfileAndAlerts invoice={row} AlertsArray={OCHesAlerts} />
    ),
    order: 2,
  },
  {
    field: 'company_MasterEntity_Name',
    headerName: 'Emisor',
    flex: 1,
    renderCell: ({ row }) => (
      <Tooltip
        TransitionComponent={Zoom}
        title={`Deudor: ${row.receiver.name}`}
        placement="top"
        arrow
      >
        <span>
          <CompanyProfileCell masterEntity={row.company.masterEntity} />
        </span>
      </Tooltip>
    ),
    order: 3,
  },
  {
    field: 'documents',
    headerName: 'Documentos',
    filterable: false,
    minWidth: 310,
    renderCell: ({ row }) => <InvoiceDocumentsCell invoice={row} />,
    order: 4,
  },
  {
    field: 'siiStatus',
    headerName: 'Estado',
    sortable: false,
    filterable: false,
    maxWidth: 90,
    renderCell: ({ row }) => <SiiStatusTextCell invoice={row} />,
    order: 8,
  },
  {
    field: 'offer_ReviewDocumentsManager_CreatedAt',
    headerName: 'Tiempo en esta etapa',
    filterable: false,
    minWidth: 150,
    renderCell: ({ row }) => (
      <TimeInStageCell
        time={row.historystatusSet.find(({ status }) => status === 'PendingDocumentsReview')?.createdAt}
      />
    ),
    order: 1,
  },
];

export const TITLE = 'Revisión de OC y HES';

export const SEARCH_PLACEHOLDER = 'Buscar por folio o nombre de cliente';

export const INITIAL_ORDER_BY = 'company_MasterEntity_Name';

export const NO_ROWS_MESSAGE = () => <Typography>Sin revisiones pendientes.</Typography>;
