import React from 'react';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { PDFIcon } from '@fingo/lib/assets';
import { openUrlAndDownload } from '@fingo/lib/helpers';
import PropTypes from 'prop-types';

const AttachmentCell = ({ row }) => (
  <Stack direction="row">
    {row?.fileSet.map(({ id, name, attachment }) => (
      <Tooltip title={name} key={id}>
        <IconButton
          disabled={!id}
          onClick={() => openUrlAndDownload(attachment.url)}
        >
          <img
            src={PDFIcon}
            alt="pdf-icon"
            style={{
              height: '24px',
              ...(!id && { filter: 'opacity(0.3)' }),
            }}
          />
        </IconButton>
      </Tooltip>
    ))}
  </Stack>
);

AttachmentCell.propTypes = {
  row: PropTypes.shape({
    fileSet: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    })),
  }).isRequired,
};

export default AttachmentCell;
