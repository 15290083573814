import React from 'react';
import { TextCell } from '@fingo/lib/components/cells';
import Cancel from '@mui/icons-material/Cancel';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { green, red } from '@mui/material/colors';
import { Tooltip } from '@mui/material';

const handleRuleResult = (row, selectedModel) => {
  if (selectedModel === 'RAS') return (<TextCell text={row.prize} />);
  return (!row.rejects
    ? <CheckCircle sx={{ color: green[700] }} /> : <Cancel sx={{ color: red[700] }} />);
};

const riskEvaluationResultsColumns = (selectedModel) => ([
  {
    field: 'rule',
    type: 'string',
    headerName: 'Regla',
    align: 'center',
    renderCell: ({ row }) => (
      <Tooltip title={row.description}>
        <TextCell
          text={row.labelName}
        />
      </Tooltip>
    ),
    flex: 1,
  },
  {
    field: 'value',
    type: 'string',
    headerName: 'Valor',
    align: 'center',
    renderCell: ({ row }) => (
      <TextCell
        text={row.value !== null ? row.value : 'n/a'}
      />
    ),
    flex: 0.5,
  },
  {
    field: 'logicalType',
    type: 'string',
    headerName: 'No debe ser',
    align: 'center',
    renderCell: ({ row }) => (
      <TextCell
        text={row.logicalType}
      />
    ),
    flex: 0.5,
  },
  {
    field: 'threshold',
    type: 'string',
    headerName: 'Threshold',
    align: 'center',
    renderCell: ({ row }) => (
      <TextCell
        text={row.threshold}
      />
    ),
    flex: 0.5,
  },
  {
    field: 'result',
    type: 'string',
    headerName: selectedModel === 'RAS' ? 'Premio' : 'Resultado',
    align: 'center',
    renderCell: ({ row }) => (
      handleRuleResult(row, selectedModel)
    ),
    flex: 0.5,
  },
]);

export default riskEvaluationResultsColumns;
